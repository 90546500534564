
.mainContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  font-size: 64px;
  font-weight: bolder;
  align-items: center;
  justify-content: center;
}


.grayd0 {
  font-size: 12px;
  background-color: #d0d0d0;
  text-align: center;
}

.grayf8 {
  font-size: 12px;
  background-color: #f8f8f8;
  text-align: center;
}

th {
  background-color: #f03c2c;
  padding: 10px;
  font-size: 22px;
  color: white;
}