.menuContainer {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    position: absolute;
    z-index: 99;
    padding-left: 20px;
    background-color: black;

}



.link {
    text-decoration: none;
    font-size: 14px;
    color: black;
    font-family: 'Barlow', sans-serif;
    font-weight: 300;
    width:100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.linkActive {
    align-items: center;
    color: red;
    display: flex;
    font-family: Barlow, sans-serif;
    font-size: 14px;
    font-weight: 300;
    justify-content: center;
    text-decoration: none;
    width:100px;
    color: white;
    background-color: #4B6FEC;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
}

.close {
    display: none;
}

.iconMenu {
    display: none;
}

@media (max-width: 800px) {
    .divButton {
        display: none;
    }

    .menuContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 20vh;
        padding: 4vh 0vh 3vh 1.5vh;
        background-color: black;
        position: absolute;
        right: 2vh;
        top: 2vh;
        height: auto;
        text-align: left;
        border-radius: 5px;
        z-index: 999;
    }

    .link {
        margin:10px; 
        font-size: 14px;
        justify-content: flex-start;
        color: #fff;
    }

    .linkActive {
       width: 90%;
       padding:5px;
       font-size: 14px;
       background-color: #4B6FEC;
       color: white;
       border-radius: 5px;
    }


    .close {
        font-family: 'Barlow', sans-serif;
        align-items: center;
        justify-content: center;
        position: absolute;
        display: flex;
        top: 0vh;
        right: 0vh;
        width: 4vh;
        height: 3vh;
        margin: 0;
        color: #fff;
        font-weight: 900;
        cursor: pointer;
    }

    .displayNone {
        display: none !important;
    }

    .iconMenu {
        position: absolute;
        z-index: 999;
        display: flex;
        right: 2vh;
        top: 1vh;
    }

    .iconMenu img {
        width: 5vh;
    }
}