html,
body {
  margin: 0 auto;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
    background-color: #F7F9FC;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  font-size: 64px;
  font-weight: bolder;
  align-items: center;
  justify-content: center;
}


.containerMessages {  
  display: grid;
  width: 80%;
}

.enviada,
.recebida,
.disconnect {
  border-radius: 10px;
}

 /* Cor da mensagem que envio*/ 
.enviada {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: right;
  justify-self: right;
  align-content: right;
  background-color: #704CEA;
  color: #FBF9FE;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  font-size: 14px;
  display: inline;
  float: right;
  clear: both;
}

.spaceR {
  padding: 3px;
  padding-right: 3px;
  font-size: 8px;
  color: gray;
  display: inline;
  float: right;
  clear: both;
}

/* Cor da mensagem que recebo*/ 
.recebida {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  justify-self: left;
  background-color: #fff;
  color: #3A3D51;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  font-size: 14px;
  display: inline;
  float: left;
  clear:both;
}

.spaceL {
  padding: 3px;
  padding-left: 3px;
  font-size: 8px;
  color: gray;
  display: inline;
  float: left;
  clear:both;
}

.disconnect {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  justify-self: left;
  background-color: #e74c4c;
  color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  font-size: 14px;
  display: inline;
  float: left;
  clear:both;
}

.centered {
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  text-align: center;
  justify-self: center;
  align-content: center;
  background-color: #44FCB6;
  font-weight: bold;
  font-size: 20px;
}


.select {
  width: 21%;
  padding: 9px; 
  background-color: #eee; 
  border-radius: 4px;
  border: 1px solid black;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}
    
.text {
  width: 49%; 
  margin: 0; padding: 0; padding: 10px; border-radius: 4px;
  border: 1px solid black;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}

.btn {
  width: 18%;
  padding: 10px; 
  background-color: #eee; 
  border-radius: 4px;
  border: 1px solid black;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}
.btn:hover {background-color:#44FCB6}
    
.btnLogout {
  padding: 10px; 
  padding-left: 14px;
  padding-right: 14px;
  background-color: #e74c4c; 
  border-radius: 4px;
  border: 1px solid black;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: white;
  width: 20%;
  align-items: center;
}

.btnReload {
  padding: 10px; 
  padding-left: 14px;
  padding-right: 14px;
  background-color: #e74c4c; 
  border-radius: 4px;
  border: 1px solid black;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: white;
  width: 80%;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 768px) {


.titleContainer {
  font-size: 32px;
  
}


.centered {
  font-size: 16px;
}

.enviada,
.recebida,
.disconnect {
  font-size: 12px;
}

.spaceL {
  padding: 2px;
  padding-left: 3px;

}

.spaceR {
  padding: 2px;
  padding-right: 3px;
}
 .text,
 .btn,
 .select {
  font-size: 10px;
 }

.btnLogout {
  font-size: 12px;
  width: 100px;
}

}