.connect {
  position: relative;
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid #2ecc71;
  border-radius: 0.6em;
  color: #2ecc71;
  cursor: pointer;
  align-self: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  padding: 20px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}

.connect:hover {
  box-shadow: 0 0 40px 40px #2ecc71 inset;
  color: white;
}



.disconnect {
  position: relative;
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid rgb(232, 72, 112);
  border-radius: 0.6em;
  color: rgb(232, 72, 112);
  cursor: pointer;
  align-self: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  padding: 20px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  display:none;
}

.disconnect:hover {
  box-shadow: 0 0 40px 40px rgb(232, 72, 112) inset;
  color: white;
}


.btnBlue {
  position: relative;
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid #4B6FEC;
  border-radius: 0.6em;
  color: #4B6FEC;
  cursor: pointer;
  align-self: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  padding: 20px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  display:none;
}

.btnBlue:hover {
  box-shadow: 0 0 40px 40px #4B6FEC inset;
  color: white;
}


.btnBlue:hover {
  box-shadow: 0 0 40px 40px #4B6FEC inset;
  color: white;
}

.btnBlue:focus {
  background-color: #4B6FEC;
  color: white;
}

.output {
  padding: 15px; 
  /*padding-left: 50px;*/
  display: flex; 
  justify-content: space-around;
  flex-wrap: wrap; 
  gap: 30px;
  
}

.cardLocation {
  border: 1px solid #cacaca;
  position: relative;
  display: inline-block; 
  overflow-wrap: break-word; 
  padding: 6px; 
  border-radius: 15px; 
  width: 210px; 
  height: 270px; 
  background: linear-gradient(rgb(75, 111, 236) 36%, rgb(255, 255, 255) 36%); 
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
}

.cardMessage {
  border: 1px solid #cacaca;
  position: relative;
  display: inline-block; 
  overflow-wrap: break-word; 
  padding: 6px; 
  border-radius: 15px; 
  width: 210px; 
  height: 270px; 
  color: black;
  background: linear-gradient(rgb(232, 72, 112) 36%, rgb(255, 255, 255) 36%); 
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
 
}


.date {
  top: -18px;
  color: white;
  font-size: 10px;
  position:inherit;
  
}

.depara {
  top: -18px;
  color: white;
  position:inherit;
  font-size: 20px;
}

.text {
  color: #67686c;
  font-size: 13px;
  position:inherit;
}

.country {
  top: -19px;
  color: white;
  position:inherit;
  font-size: 14px;
  ;
}


.cardimageLocation {
  position: absolute; /* Position image relative to card */
  top: 3px; /* Adjust top position to move image up */
  right: 10px; /* Move image to right side of card */
  /* width: 40px; */
  /* height: auto; */
  font-size: 30px;
  color: white;
  
}

.cardimageMessage {
  position: absolute; /* Position image relative to card */
  top: 3px; /* Adjust top position to move image up */
  right: 10px; /* Move image to right side of card */
  /* width: 40px; */
  /* height: auto; */
  font-size: 30px;
  color: white;
  
  
}

.cardImageBGLocation {
  position: absolute;
  width: 210px;
  height: 45px;
  background-image: url('https://i.stack.imgur.com/HILmr.png');
  background-position: center top; 
  background-size: cover; 
  background-repeat: no-repeat;
  padding-top: 100%; /* Para deixar apenas no header azul mudar para 20% */
  opacity:0.1;
  border-radius: 10px;
  
  
}


.cardImageBGMessage {
  position: absolute;
  width: 210px;
  height: 45px;
  background-image: url('https://simpletexting.com/wp-content/uploads/2020/11/delivery-service-text-messages.jpg');
  background-position: center top; 
  background-size: cover; 
  background-repeat: no-repeat; 
  padding-top: 100%; /* Para deixar apenas no header azul mudar para 20% */
  opacity:0.2;
  border-radius: 10px;
  
  
}



.cardCounter {
    position: absolute; /* Position image relative to card */
    top: 1px; /* Adjust top position to move image up */
    right: 1px; /* Move image to right side of card */
    /* width: 40px; */
    /* height: auto; */
    border-radius: 100px;
    padding: 4px;
    font-size: 10px;
    color: white;
    font-weight: bold;
    background-color: rgb(232, 72, 112);
}


.select {
  width: 200px;
  padding: 9px; 
  background-color: #eee; 
  border-radius: 4px;
  border: 1px solid black;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  padding-top: 18px;
  padding-bottom: 18px;
}