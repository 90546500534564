
.consol {
  visibility: hidden;
}

.tableDial {
  background-color:#000;
  margin:0 auto 50px;
  border: 1px solid #999;
  border-radius: 15px;
  padding: 30px;
}
/*
button,strong{
  font-family:courier,"Courier New",monospace
}
*/


.beginDial{
  float:left;
  margin:9px;
  padding:0px;
  
  color:white;
  border-style:none;
  text-align:center;
  vertical-align:middle;
  outline:none;

  height:50px;
  background-color:#4B6FEC;
  width:60px;
  border-radius:3px;
  font-size:16px;
  font-weight: bold;
}

.beginDial:active{
  background-color: #2ecc71;
}



.spanInput {
      font-size: 15px;
      padding: 2px;
      text-align:center;
      width: 300px;
      padding-left: 5px;
      padding-Right: 10px;
     
}

.spanButton {
  background-color:#4B6FEC;
  color: white;
  padding: 4px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 3px;
  font-size: 16px;
}

.spanButton:hover {
  background-color: #2ecc71;
  cursor: pointer;
}

.title {
  font-size: 26px;
  padding: 10px;
  font-weight: bold;
}
