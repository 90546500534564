* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  height: 100vh;
}

/* height of the container */
.leaflet-container {
  height: 100%;
  
}
/* required styles */
.horizon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin-bottom: 5px;
}

.infobox {
  top: 80px;
  padding: 10px;
  font: 10px;
  background: #000;
  color: white;
  box-shadow: 0 0 15px rgba(0,0,0,.2);
  border-radius: 5px;
  text-align: left;
  position: absolute;
  right: 10px;
  z-index: 900;
  cursor: pointer;
  max-width: 160px
}
