
.mainContainer {
  background-color: #F0F0F0;
  height:0vmax;
}

.containerMessages {  
  display: grid;
  width: 80%;
  
}

.titleContainer {
  display: flex;
  flex-direction: column;
  font-size: 64px;
  font-weight: bolder;
  align-items: center;
  justify-content: center;
}

.message {
  color: black;
  font-size: 14px;
  padding-bottom: 5px;
}

.connected {
  border-radius: 4px;
  width: 400px;
  padding: 10px; 
  
  background-color: #44FCB6;
}


.disconnected {
  color: red;
  font-size: 50px;
}

.grafico {
  border-radius: 4px;
  width: 400px;
  padding: 10px; 
  background-color: white;
}

.graficoAguardando {
  border-radius: 4px;
  width: 400px;
  padding: 10px;
  padding-top: 100px; 
  padding-bottom: 100px; 
  background-color: white;
}


.space {
  padding: 5px;
}



@media screen and (max-width: 768px) {

  .titleContainer {
    font-size: 32px;
    
  }
  
  .grafico {
    width: 300px;
    padding: 10px; 
   
  }
  
  .connected {
    width: 300px;
    padding: 10px; 
   
  }

  .graficoAguardando {
    width: 300px;
   
  }

  
  }