
.mainContainer {
  position: relative;
  width: 90%;
  border: 1px solid black;
  border-radius: 5px;
  padding: 20px;
  background-color: black;
  color: #00ff76;
  text-align: left;
}

.btnConnect {
  padding: 10px;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btnSend {
  padding: 10px;
  background-color:#4B6FEC;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btnDisconnect {
  padding: 10px;
  background-color: rgb(232, 72, 112);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer
}

.btnClear {
  position: absolute;
  top:10px;
  right: 10px;
  padding: 10px;
  background-color:#b3b3b5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.inputMessage {
  padding: 10px;
  width: 500px;
  border-radius: 5px;
  border: 1px solid black;
}
